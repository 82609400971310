class Error {
    constructor(errors = {}) {
        this.errors = errors;
    }

    set(errors) {
        this.errors = errors;
    }

    has(prop) {
        return Object(this.errors).hasOwnProperty(prop);
    }

    first(prop) {
        if (!this.has(prop)) {
            return;
        }

        if (!this.errors[prop].length) {
            return;
        }

        return this.errors[prop][0];
    }

    remove(prop = undefined) {
        if (prop === undefined) {
            this.errors = {};

            return;
        }

        this.errors = Object.keys(this.errors).reduce((object, key) => {
            if (key !== prop) {
                object[key] = this.errors[key];
            }

            return object;
        }, {})
    }
}

export default Error;